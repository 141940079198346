import { MDBBreadcrumb, MDBBreadcrumbItem } from 'mdbreact';

import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

const MDBBreadcrumbComp = styled(MDBBreadcrumb)`
  background-color: transparent!important;
  padding: 0em;
  margin: 0.3em 0em;
  font-size: 0.9em;
`

const BreadcrumbPage = (props) => {
  return (
      <MDBBreadcrumbComp>
        <MDBBreadcrumbItem><Link to="/">Home</Link></MDBBreadcrumbItem>
        <MDBBreadcrumbItem><Link to="/about-us/company-profile/leadership-team">Leadership Team</Link></MDBBreadcrumbItem>
        <MDBBreadcrumbItem>{props.title}{''}</MDBBreadcrumbItem>
      </MDBBreadcrumbComp>
  );
};

export default BreadcrumbPage;