import { MDBCard, MDBCardImage, MDBCol, MDBRow } from 'mdbreact';

import InlineBodyTransform from '../inlinebodytransform';
import React from 'react';
import styled from 'styled-components';

const MDBCardComp = styled(MDBCard)`
    background-color: #d4d5d6;
    height: 98%;
    h2 {
        font-size: 2.2rem;
        line-height: 2.2rem;
    }
    h3 {
        font-size: 1.4rem;
        line-height: 1.4rem;
        text-shadow: 2px 2px #d4d5d6;
    }
    div.Ripple-parent{ 
        text-align: center;
        min-height: 130px;
        line-height: 130px;
        img {
            margin: 10px auto;
            display: inline;
            border-radius: .25rem;
            box-shadow: 3px 3px #d4d5d6;
            margin-bottom: 20px;
        }
    }
`

const MemberPage = (props) => {
    return (
        <MDBCol xs="12" md="12" lg="12" className="col-12 px-3">
            <MDBCardComp className="p-2">
                <MDBRow center>
                    <MDBCol xs="8" md="8" lg="8" className="col-8 px-3">
                        <h2>{props.title}</h2>
                        <h3>{props.sub_title}</h3>
                    </MDBCol>
                    <MDBCol xs="4" md="4" lg="4" className="col-4 px-3">
                        <MDBCardImage src={encodeURI(props.image)} alt={props.sub_title} waves /> 
                    </MDBCol>
                </MDBRow>
                <InlineBodyTransform bodyValue={props.description} />
            </MDBCardComp>
        </MDBCol>
    )
}

export default MemberPage;